import { gql } from "graphql-request";

const vehicleDetailFragment = gql`
  fragment VehicleDetails on Vehicle {
    vehicleId
    brand {
      insideId
      name
      slug
      logoPictureUrl
      contentTeaserHeadline
    }
    model {
      insideId
      name
      slug
      thumbnailPictureUrl
      thumbnailPictureAltText
    }
    equipmentPackage
    title
    priceInitialB2cGross
    pricePreparationGross
    priceDownpaymentGross
    vehicleType {
      name
    }
    priceB2cGross
    enabled
    slug
    imageUrls
    videoUrls
    options
    reserved
    firstRegistration
    lastInspection
    lastInspectionDate
    km
    kw
    batteryRange
    batteryCapacity
    ps
    ccm
    cylinderNum
    trailerWeightMax
    emptyWeight
    doorNum
    seatNum
    vin
    factoryCode
    typenschein
    warranty
    equipmentPackage
    upholstery {
      name
    }
    productionDate
    bodyType {
      insideId
      name
      group {
        id
        name
        groupId
      }
    }
    vehicleCondition {
      insideId
      name
      salesAdvisor
      structuredData
      termsAndConditionsAttribute
      code
    }
    exteriorColor {
      name
    }
    interiorColor {
      name
    }
    fuelType {
      insideId
      name
      group {
        id
        name
        groupId
      }
    }
    transmission {
      insideId
      name
      group {
        id
        name
        groupId
      }
    }
    driveType {
      insideId
      name
      code
    }
    vehicleClass {
      insideId
      name
    }
    leasingRateFrom
    leasingRateFromCalcBasis {
      legalText
      kmPerYear
      totalRuntime
      downPayment
    }
    qualityChecked {
      insideId
      name
      description
      logoImageUrl
      checkpoints
      coverageText
      coverageMonths
      coverageMileage
      brand {
        name
        slug
      }
    }
    promotionList {
      promotions {
        name
        priceGross
        paPromotion {
          active
          description
          legalLine
          validFromDate
          validUntilDate
        }
      }
    }
    dealer {
      id
      insideId
      name
      subname
      slug
      logoPictureUrl
      street
      zip
      city
      phone
      calltrackingPhone
      openingHours @include(if: $includeOpeningHours)
      workingHoursExceptions @include(if: $includeOpeningHours)
      homeDeliveryAvailable
      homeDeliveryFee
      carmarketReturnPolicy
      calltrackingSubscriberId
      newVehicleTcUrl
      usedVehicleTcUrl
      privacyPolicyUrl
      leasingApplicationAvailable
      salesAdvisorList {
        salesAdvisors {
          employee {
            firstname
            name
            phone
            email
            profilePictureUrl
          }
          brands {
            insideId
          }
          vehicleCondition
        }
      }
      gReviews @include(if: $includeReviews)
      externalIds
    }
    emissionClass {
      name
    }
    pollutionClass {
      name
    }
    consumptionCity
    consumptionCountry
    consumptionComb
    co2
    commissionNumber
    productionDate
  }
`;

export default vehicleDetailFragment;
