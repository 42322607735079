import React from "react";
import i18next from "i18next";
import { Currency, VehiclePromotion } from "../../hygraph/vo";
import useFormatter from "../../common/hooks/useFormatter";
import PopupContent from "../../visual-components/components/PopupContent";

type Props = {
  promotion: VehiclePromotion;
  currency: Currency;
};

const PromotionInfoBody: React.FC<Props> = ({ promotion, currency }) => {
  const { formatCurrency } = useFormatter();
  const priceGrossText = formatCurrency(promotion.priceGross, currency);
  const promotionInfo = promotion.paPromotion;

  return (
    <PopupContent
      gradient
      title={promotion.name ?? i18next.t("PROMOTION")}
      body={
        <>
          <p>{priceGrossText}</p>
          {promotionInfo ? (
            <>
              <p>{promotionInfo.description}</p>
              <p>{promotionInfo.legalLine}</p>
            </>
          ) : null}
        </>
      }
    />
  );
};

export default PromotionInfoBody;
