import React, { ReactNode, useState } from "react";
import i18next from "i18next";
import { StringParam } from "use-query-params";
import { useSearchParams } from "react-router-dom";
import { Trans } from "react-i18next";
import getAggregatedConfigurationCount from "..//helpers/getAggregatedConfigurationCount";
import useVehicleSearchData, {
  SEARCH_QUERY_PARAM,
  SORT_QUERY_PARAM,
  useAllVehicleSearchQueryParams,
} from "../../algolia/hooks/useVehicleSearchData";
import Button from "../../visual-components/components/Button";
import { useFilterTrack } from "../../analytics/hooks/useFilterTrack";
import StatusSmall from "../../../processes/matchmaker/components/access/StatusSmall";
import Banner from "../../../processes/matchmaker/components/access/Banner";
import PopularSearches from "../../common/components/PopularSearches";
import usePopularSearchesFromFacets from "../../common/hooks/usePopularSearchesFromFacets";
import { usePreventBackgroundScroll } from "../../visual-components/hooks/usePreventBackgroundScroll";
import Divider from "../../cms/components/Divider";
import SaveSearchOverlay from "./save-search/SaveSearchOverlay";
import VehicleSearchHeader from "./VehicleSearchHeader";
import FilterPanel from "./FilterPanel";
import SearchInput from "./SearchInput";
import SelectedFilter from "./SelectedFilter";
import FilterTool from "./FilterTool";
import Results from "./Results";
import PowerSearch from "./PowerSearch";
import { useOverlayContext } from "./OverlayContext";
import DefaultNoResults from "./DefaultNoResults";

type Props = {
  title: string;
  headingLevel?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  searchPlaceholder?: string;
  vehicleSearchData: ReturnType<typeof useVehicleSearchData>;
  noResults?: ReactNode;
  enableSaveSearch?: boolean;
  disableMinHeightFix?: boolean;
  hasMatchmaker?: boolean;
  hasAds?: boolean;
  showPopularSearches?: boolean;
};

const VehicleSearch: React.FC<Props> = ({
  title,
  headingLevel,
  searchPlaceholder,
  noResults,
  vehicleSearchData,
  enableSaveSearch = false,
  disableMinHeightFix,
  hasMatchmaker = false,
  hasAds = false,
  showPopularSearches = false,
}) => {
  const {
    appliedFilters,
    resetFilters,
    setAllQueryParams,
    vehicleData,
    filterData,
    setPostedQuery,
    postedQuery,
    setPage,
    page,
    sortCriteria,
    sortOptions,
    setSortCriteria,
    algoliaOptions,
  } = vehicleSearchData;
  const configurationCount = getAggregatedConfigurationCount(appliedFilters, postedQuery);

  const vehicleCount = vehicleData?.nbHits;
  const queryId = vehicleData?.queryID;

  const { isOpen, setIsOpen } = useOverlayContext();
  const [isSaveSearchOverlayOpen, setIsSaveSearchOverlayOpen] = useState(false);
  usePreventBackgroundScroll(isOpen, "auto");

  const allQueryParams = useAllVehicleSearchQueryParams();

  // explicit type "string" permits nice access based on string key, the type could be further narrowed down if needed
  const queryParamWhitelist: Record<string, any> = {
    ...allQueryParams,
    [SEARCH_QUERY_PARAM]: StringParam,
    [SORT_QUERY_PARAM]: StringParam,
  };

  const [allSearchParams] = useSearchParams();
  const whitelistedSearchParams = Array.from(allSearchParams.entries()).filter(([key]) => !!queryParamWhitelist[key]);
  const persistableSearchParams = new URLSearchParams(whitelistedSearchParams);

  const popularSearches = usePopularSearchesFromFacets(
    vehicleData?.facets,
    filterData,
    { appliedFilters },
    !showPopularSearches,
  );

  const showSaveSearchOverlay = enableSaveSearch
    ? () => {
        setIsSaveSearchOverlayOpen(true);
      }
    : undefined;

  useFilterTrack(appliedFilters, vehicleCount, queryId);

  return (
    <>
      <VehicleSearchHeader headingLevel={headingLevel} title={title} vehicleCount={vehicleCount} />
      <aside className="col-span-3 col-filter">
        {filterData ? (
          <>
            <FilterPanel
              appliedFilters={appliedFilters}
              filterData={filterData}
              searchData={vehicleData}
              showSaveSearchOverlay={showSaveSearchOverlay}
              onShowSearchOverlay={() => {
                setIsOpen(true);
              }}
            />
            {hasMatchmaker ? <Banner /> : null}
          </>
        ) : null}
      </aside>
      <div className="fix-btn-center-bottom">
        <Button
          icon="filter"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {i18next.t("SHOW FILTERS")}
        </Button>
      </div>
      <div className="col-span-9 col-search-result">
        <SearchInput placeholder={searchPlaceholder} postedQuery={postedQuery} setPostedQuery={setPostedQuery} />
        {hasMatchmaker ? (
          <div className="search__meta-nav">
            <StatusSmall />
          </div>
        ) : null}
        {filterData ? (
          <SelectedFilter appliedFilters={appliedFilters} filterData={filterData} updateFilters={setAllQueryParams} />
        ) : (
          <div className="filter-selected-wrap" />
        )}
        <FilterTool
          configurationCount={configurationCount}
          hasMatchmaker={hasMatchmaker}
          resetFilters={resetFilters}
          setSortCriteria={setSortCriteria}
          showSaveSearchOverlay={showSaveSearchOverlay}
          sortCriteria={sortCriteria}
          sortOptions={sortOptions}
          showSearchOverlay={() => {
            setIsOpen(true);
          }}
        />
        <Results
          disableMinHeightFix={disableMinHeightFix}
          hasAds={hasAds}
          page={page}
          setPage={setPage}
          vehicleData={vehicleData}
          noResults={
            noResults ? (
              noResults
            ) : (
              <DefaultNoResults
                algoliaOptions={algoliaOptions}
                postedQuery={postedQuery}
                resetFilters={resetFilters}
                showSaveSearchOverlay={showSaveSearchOverlay}
              />
            )
          }
        />

        {showPopularSearches && popularSearches.length > 0 ? (
          <>
            <Divider />
            <PopularSearches searches={popularSearches} title={<Trans i18nKey="OTHERS SEARCHED FOR" />} />
          </>
        ) : null}
      </div>
      <PowerSearch
        configurationCount={configurationCount}
        filterData={filterData}
        open={isOpen}
        resetFilters={resetFilters}
        searchData={vehicleData}
        showSaveSearchOverlay={showSaveSearchOverlay}
        vehicleCount={vehicleCount}
        onClose={() => setIsOpen(false)}
      />
      {enableSaveSearch ? (
        <SaveSearchOverlay
          open={isSaveSearchOverlayOpen}
          close={() => {
            setIsSaveSearchOverlayOpen(false);
          }}
          searchInfo={{
            title: title,
            searchParams: persistableSearchParams.toString(),
            algoliaOptions,
            query: postedQuery?.length > 0 ? postedQuery : undefined,
          }}
        />
      ) : null}
    </>
  );
};

export default VehicleSearch;
